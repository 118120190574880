.hero-particles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  
}

//portfolio image modal
.portfolio-image-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  img {
    max-width: 80%;
    max-height: 80%;
  }
  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
